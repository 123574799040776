import dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import { EStatus } from '../components/parts/status-badge/status-badge.model';
import { v4 as uuidv4 } from 'uuid';

export enum EQuoteType {
  QUOTE,
  ORDER
}

export enum EScheme {
  GEN = 'GEN',
  TLA = 'TLA',
  APP = 'APP',
  TL = 'TL'
}

export enum EPartStatus {
  UNQUOTED = 'Unquoted',
  ON_ORDER = 'On Order',
  CONFIRMED = 'Confirmed',
  DELAYED = 'Delayed',
  SHIPPED = 'Shipped',
  DELIVERED = 'Delivered',
  CANCELLED = 'Cancelled',
  SPLIT = 'Split'
}

export enum PartLookupMessages {
  CUSTOMER_DISCOUNT_CODE_NOT_FOUND = 'Customer ID or Grouping not defined for this customer, please set the discount(s) manually',
  CUSTOMER_ID_NOT_FOUND = 'Customer ID or Grouping not defined for this customer, please set the discount(s) manually',
  DATA_ERROR = 'Part number not found. Please either correct the part number for another search attempt\n or manually update the ' +
    'pricing to proceed with submitting the quote.',
  PART_ID_NOT_FOUND = 'Part code not found; please check the part number and the discount',
  PART_GROUP_ID_NOT_FOUND = 'Part Group not defined for this part; please set the discount manually',
  PART_DISCOUNT_NOT_FOUND = 'Discount not found for this customer; please set the discount(s) manually',
  PART_MATCH = 'Part number successfully matched! The description, recommended retail price (RRP), and trade price have been updated.'
}

export interface FetchError {
  data: boolean;
  lookup: boolean;
}

export class CPart {
  _id?: string;
  added?: boolean;
  addedBy?: string;
  addedByRepairer?: boolean;
  addedReason?: string;
  cancelledBy?: string;
  cancelReason?: string;
  createdAt?: Dayjs;
  delayedAt?: Dayjs;
  delayedBy?: string;
  delayedUntil?: Dayjs;
  delayReason?: string;
  description = '';
  discount?: string;
  discountAmount?: number;
  discountMaximum?: string;
  grouped?: boolean;
  invoicedAt?: Dayjs;
  invoicedBy?: string;
  invoiceNumber?: string;
  kitHeader?: boolean;
  locked?: boolean;
  lookupMatched? = false;
  lookupWarnings?: string[] = [];
  match? = false;
  net = '';
  ordinal?: number;
  partCode?: string;
  partId?: string;
  partNumber = '';
  partSplitChanges?: CPartSplitChange[] | null;
  possibleToSplit? = false;
  quantity = 1;
  quoteRequestId?: string;
  removed?: boolean;
  removedByRepairer?: boolean;
  removedReason?: string;
  rrp = '';
  splitChangeId?: string;
  status?: string;
  surcharge?: string;
  uniqueOrderId?: string;
  uniquePartId?: string;
  unit?: string;
  updated? = false;
  updatedAt?: Dayjs;
  addNewPart? = false;

  constructor(part?: CPart) {
    if (!part) {
      return this;
    }
    this._id = part._id || '';
    this.added = part.added || false;
    this.addedBy = part.addedBy || '';
    this.addedByRepairer = part.addedByRepairer || false;
    this.addedReason = part.addedReason || '';
    this.cancelledBy = part.cancelledBy || '';
    this.cancelReason = part.cancelReason || '';
    this.createdAt = part.createdAt;
    this.delayedAt = part.delayedAt;
    this.delayedBy = part.delayedBy || '';
    this.delayReason = part.delayReason || '';
    this.delayedUntil = part.delayedUntil;
    this.description = part.description || '';
    this.discount = part.discount || '0.00';
    this.discountMaximum = part.discountMaximum || '0.45';
    this.discountAmount = part.discountAmount || 0;
    if (part.grouped) {
      this.grouped = true;
    }
    this.invoicedAt = part.invoicedAt;
    this.invoicedBy = part.invoicedBy || '';
    this.invoiceNumber = part.invoiceNumber || '';
    if (part.kitHeader) {
      this.kitHeader = true;
    }
    this.locked = part.locked || false;
    this.lookupMatched = part.lookupMatched || false;
    this.lookupWarnings = part.lookupWarnings || [];
    this.match = part.match || false;
    this.net = part.net || '1';
    this.ordinal = part.ordinal || 1;
    this.partCode = part.partCode || '';
    this.partId = part.partId || '';
    this.partNumber = part.partNumber || '';
    this.partSplitChanges = part.partSplitChanges || null;
    this.quantity = part.quantity || 1;
    this.quoteRequestId = part.quoteRequestId || '';
    this.removed = part.removed || false;
    this.removedByRepairer = part.removedByRepairer || false;
    this.removedReason = part.removedReason || '';
    this.rrp = part.rrp || '1';
    this.status = part.status || EPartStatus.UNQUOTED;
    this.surcharge = part.surcharge || '0';
    this.uniqueOrderId = part.uniqueOrderId || '';
    this.uniquePartId = part.uniquePartId || uuidv4();
    this.unit = part.unit || '1';
    this.updated = part.updated || false;
    this.updatedAt = part.updatedAt;
    this.addNewPart = false;
  }
}

export class CTLAPart extends CPart {
  tlaDiscount?: string;
  tlaDiscAmount?: string;
  tlaPrice?: string;
  tlaDisplayPrice?: string;
  icop?: string;
  constructor(part?: CTLAPart) {
    super(part);
    this.tlaDiscount = part.hasOwnProperty('tlaDiscount') ? part.tlaDiscount : '0';
    this.tlaDiscount = part.hasOwnProperty('tlaDiscAmount') ? part.tlaDiscAmount : '0';
    this.tlaDiscount = part.hasOwnProperty('tlaPrice') ? part.tlaPrice : part.unit;
    this.tlaDiscount = part.hasOwnProperty('tlaDisplayPrice') ? part.tlaDisplayPrice : part.net;
    this.tlaDiscount = part.hasOwnProperty('icop') ? part.icop : '0';
  }
}
export class CAppPart extends CPart {
  appDiscount?: string;
  appPrice?: string;
  afterMarket?: boolean;
  appDiscountThresholdLower?: number;
  appDiscountThresholdUpper?: number;
  appPlotPointHigh?: number;
  appPlotPointRecommended?: number;

  constructor(part?: CAppPart) {
    super(part);
    this.appDiscount = part.hasOwnProperty('appDiscount') ? part.appDiscount : '0';
    this.appPrice = part.hasOwnProperty('appPrice') ? part.appPrice : part.net;
    this.afterMarket = part.hasOwnProperty('afterMarket') ? part.afterMarket : false;
    if (part.appDiscountThresholdLower) {
      this.appDiscountThresholdLower = part.appDiscountThresholdLower;
    }
    if (part.appDiscountThresholdUpper) {
      this.appDiscountThresholdUpper = part.appDiscountThresholdUpper;
    }
    if (part.appPlotPointHigh) {
      this.appPlotPointHigh = part.appPlotPointHigh;
    }
    if (part.appPlotPointRecommended) {
      this.appPlotPointRecommended = part.appPlotPointRecommended;
    }
  }
}

export type TPartFields =
  | 'partNumber'
  | 'description'
  | 'quantity'
  | 'rrp'
  | 'surcharge'
  | 'discount'
  | 'unit'
  | 'net';

export type TTlaPartFields = 'tlaDiscount' | 'tlaPrice';

export type TAppPartFields = 'appDiscount' | 'appPrice';

export type TPartLookupFields = 'lookupWarnings' | 'lookupMatched';

export type TAllPartFields = TPartFields | TTlaPartFields | TAppPartFields;

export type TPartUpdate = TAllPartFields | TPartLookupFields;

export class CPartSplitChange {
  _id = '';
  active: boolean;
  createdAt: Dayjs = dayjs();
  invoicedAt: Dayjs = dayjs();
  invoiceNumber = '';
  quantity: number;
  rrp: number;
  partNumber: string;
  status: string;
  uniquePartId: string;
  updatedAt: Dayjs = dayjs();

  constructor(data: any) {
    if (data._id) {
      this._id = data._id || '';
    }

    this.active = data.active || false;

    if (this.createdAt) {
      this.createdAt = data.createdAt || null;
    }

    if (data.invoiceNumber || data.invoicedAt) {
      this.invoicedAt = data.invoicedAt;
      this.invoiceNumber = data.invoiceNumber;
    }

    this.partNumber = data.partNumber || '';
    this.quantity = data.quantity || 1;
    this.rrp = data.rrp;
    this.status = data.status || EPartStatus.CONFIRMED;
    this.uniquePartId = data.uniquePartId || '';

    if (data.updatedAt) {
      this.updatedAt = data.updatedAt || null;
    }
  }
}

export interface IVehicle {
  _id: string;
  vin: string;
  make: string;
  model: string;
  registration: string;
  firstRegDate: dayjs.Dayjs;
  engineSize: string;
  engine: string;
  desc: string;
  colour?: string;
  uniquePartId: string;
  quote: string;
  isEdited: boolean;
  edited: boolean;
  confirmed: boolean;
}

// used for updating in side pane, confirm vehicle quote alert...
export interface IVehicleUpdate {
  _id?: string;
  colour: string;
  make: string;
  model: string;
  registration: string;
  vin: string;
}

export interface IAssessment {
  bottomLineDiscount: number;
  preAccidentValue: number;
  totalLossPercentage: number;
  totalNonPartCost: number;
  workProviderDiscount: number;
}

// QUOTES / VEHICLES
export class CQuote {
  _id = '';
  address: IAddress = { postcode: '', street: '', country: '' };
  assessment?: IAssessment;
  centreCode = '';
  convertedDate?: Dayjs;
  convertedBy?: string;
  createdAt: Dayjs = dayjs();
  createdBy = '';
  createdDate: Dayjs = dayjs();
  inUseBy: string;
  locked?: boolean;
  order?: COrder;
  parts: CPart[] = [];
  quoteAction?: EQuoteAction = EQuoteAction.OTHER;
  quotedBy = '';
  quotedDate: Dayjs = dayjs();
  quoteNumber = '';
  quoteRequestId?: string; // Used to be called Estimate
  quoteScheme?: EScheme = EScheme.GEN;
  quoteStatus?: EStatus = EStatus.NEW;
  removedParts?: CPart[] = [];
  repairer?: IRepairer;
  submittedAt?: Dayjs;
  submittedBy?: string;
  updatedAt?: Dayjs;
  updatedBy?: string;
  vehicle?: IVehicle;
  workProvider: IProvider = { code: '', name: '' };
}

export class COrder {
  _id = '';
  centreCode = '';
  customerOrderReferenceNumber?: string;
  orderedAt?: dayjs.Dayjs;
  orderInstruction?: string;
  orderNumber?: string;
  parts: CPart[] = [];
  partsDelayed?: boolean;
  quote?: CQuote;
  status = '';
  updatedAt?: dayjs.Dayjs;
  part?: CPart;
}

export interface IAddress {
  postcode: string;
  buildingNumber?: string;
  buildingName?: string;
  street: string;
  county?: string;
  country: string;
}

export enum EQuoteAction {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
  OTHER = 'OTHER'
}

export interface IRepairer {
  accountNo: string;
  address: IAddress;
  contactNo: string;
  email: string;
  repairerId: number;
  repairerName: string;
  siteCode: string;
}

export interface IProvider {
  code: string;
  name: string;
}

export interface IErrorUpdate {
  id: string;
  errors: boolean;
}

export interface IPartError {
  field: string;
  errors: string[];
}

/*
  DETERMINES HOW DECIMALS GREATER THAN 2 DECIMAL PLACES I.E.: PRICE AFTER DISCOUNT ARE ROUNDED
*/
export enum ERoundingScheme {
  AUTO, // WILL ROUND UP / DOWN DECIMALS DEPENDING ON VALUE
  ROUND_UP, // WILL ALWAYS ROUND UP DECIMALS
  ROUND_DOWN // WILL ALWAYS ROUND DOWN DECIMALS
}

export enum EPartGroupType {
  SOLO = 'solo', // NOT A PART OF A GROUP
  GROUP_TITLE = 'group_title', // GROUP TITLE / HEADER PART
  GROUP_MEMBER = 'group_member'
}

export interface IPartGroupOwner {
  id: string;
  partCode: string;
}
